// import {getCookie, removeCookie, setCookie} from "./cookiehandler";
import {default as storage} from "./storage/localstorage";
import {cnEls, updateOptions} from "./cookienotice";
import {cookieState} from "./index";
import {lang} from "./helpers";


export function setVBCNCookie(cookieValue) {
    let cookieValueStr = null;
    if(typeof cookieValue === "object") {
        cookieValueStr = JSON.stringify(cookieValue);
    } else {
        cookieValueStr = cookieValue
    }

    let endDate = new Date();

    switch (cookieState.settings.cookie.lifeTimeUnit) {
        case "month":
            endDate.setMonth(endDate.getMonth() + cookieState.settings.cookie.cookieLifeTime);
            break;
        case 'day':
            endDate.setDate(endDate.getDate() + cookieState.settings.cookie.cookieLifeTime);
            break;
        case 'session':
            endDate = false;
            break;
        default:
            endDate.setMonth(endDate.getMonth() + cookieState.settings.cookie.cookieLifeTime);
            break;
    }


    storage.set(cookieState.settings.cookie.cookiename, cookieValueStr, endDate, '/', window.location.host, cookieState.settings.cookie.secure ? 'true' : '');
    cookieState.cookiesAllowed = true;
    cookieState.allowedData = cookieValue;

    window.cookieState = cookieState;

    updateOptions(cookieValue.options);

}

export function unsetVBCNCookie() {
    storage.remove(cookieState.settings.cookie.cookiename, '/', window.location.host, cookieState.settings.cookie.secure ? 'true' : '');

    if(cookieState) {
        cookieState.cookiesAllowed = false;

        let allowedData = cookieState.allowedData;

        if(allowedData) {
            allowedData.cookiesAllowed = false;

            let options = cookieState.allowedData.options;

            for (let option in options) {
                if(options.hasOwnProperty(option)) {
                    options[option] = false;
                }
            }

            cookieState.allowedData.options = options;
            updateOptions(options)
        }
    }
}

export function renewVBCNCookie() {
    let currentData = getVBCNCookieData();
    setVBCNCookie(currentData);
}

export function enableSpecificType(type) {
    let currentData =  getVBCNCookieData();

    if(!currentData) {
        currentData = collectVBCNCookieData();
        if (!currentData.cookiesAllowed) {
            currentData = updateCookieData(currentData);
        }
    }

    if(currentData.options.hasOwnProperty(type)) {
        currentData.options[type] = true;
    } else {
        currentData.options[type] = true;
    }
    unsetVBCNCookie();
    setVBCNCookie(currentData);
}

export function updateCookieData(cookieData) {

    if(cookieState.settings.notice.autoEnableMandatoryOnPlaceholder) {
        cookieData['cookiesAllowed'] = true;
        let mandatoryOptions = cookieState.settings.sets[lang].filter(cookieSet => cookieSet.mandatory);

        mandatoryOptions.forEach(option => {
            cookieData.options[option.id] = true;
        })
    }

    return cookieData;
}

export function updateVBCNCookieData(key, data) {
    let currentData =  getVBCNCookieData();

    if(!currentData) {
        currentData = collectVBCNCookieData();
    }

    if(currentData.hasOwnProperty(key)) {
        currentData[key] = data;
    } else {
        currentData[key] = null;
        currentData[key] = data;
    }

    unsetVBCNCookie();
    setVBCNCookie(currentData);
}


export function getVBCNCookieData() {
    let cookieValue = storage.get(cookieState.settings.cookie.cookiename);

    try {
        cookieValue = JSON.parse(cookieValue);
    } catch (e) {
        //cookieValue is not Json
    }

    return cookieValue;
}


export function collectVBCNCookieData() {
    let cookieValue = {
        cookiesAllowed: null,
        options: {}
    };

    let mandatoryOptions = cookieState.settings.sets[lang].filter(cookieSet => cookieSet.mandatory);

    cnEls.cnOptions.forEach(option => {
        let input = option.querySelector('.vbcn-option-input');
        let optionID = input.dataset.option;
        cookieValue.options[optionID] = input.checked;
    });

    let uncheckedMandatory = mandatoryOptions.filter(option => {
        return cookieValue.options[option.id] !== true;
    });

    cookieValue.cookiesAllowed = uncheckedMandatory.length <= 0;

    return cookieValue;
}


export function checkVBCNCookieState(key) {
    return !!(cookieState
        && cookieState.cookiesAllowed
        && cookieState.allowedData.options.hasOwnProperty(key)
        && cookieState.allowedData.options[key]);
}
