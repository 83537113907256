import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch';
import {debounce} from "./helpers";

let submitButtons = document.querySelectorAll('.header_search-submit');

let currentPage = 'evk_website_de';

if (document.body.classList.contains('style-evk')) {
    currentPage = 'evk_website_de';
} else if (document.body.classList.contains('style-ambulante')) {
    currentPage = 'ambulante_de';
} else if (document.body.classList.contains('style-mitarbeiter-portal')) {
    currentPage = 'mitarbeiterportal_de';
} else if (document.body.classList.contains('style-kariereportal')) {
    currentPage = 'karriereportal_de';
} else if (document.body.classList.contains('style-handbuch')) {
    currentPage = 'handbuch_de';
} else if (document.body.classList.contains('style-mvz')) {
    currentPage = 'mvz_de';
} else if (document.body.classList.contains('style-diakoniewerk')) {
    currentPage = 'diakoniewerk_de';
} else if (document.body.classList.contains('style-wichernhaus')) {
    currentPage = 'wichernhaus_de';
} else if (document.body.classList.contains('style-kjhaus')) {
    currentPage = 'kjhaus_de';
} else if (document.body.classList.contains('style-seniorenstift')) {
    currentPage = 'seniorenstift_de';
} else if (document.body.classList.contains('style-beratungsdienste')) {
    currentPage = 'beratungsdienste_de';
} else if (document.body.classList.contains('style-evk-ruhr-karriere')) {
    currentPage = 'ruhr_karriere_de';
} else if (document.body.classList.contains('style-evk-ruhr-map')) {
    currentPage = 'ruhr_map_de';
}

let currentKey = algoliasearch('FIHO7V1M1C', 'b6b7235db96e2637d4bd59e90d055138');

const searchClient = currentKey;

function buildSearchPageQuery(state) {
    // if(state.query.length <= 0) {
    //     return null;
    // }

    let baseurl = window.location.protocol + '//' + window.location.host;
    let tempQuery = state.query.length > 0 ? encodeURIComponent(state.query) : '';
    let url = baseurl + '/suche?query='+ tempQuery;

    url = url.replace(/\+/g, '%20');

    return url;
}

function updateOnStateChange(state) {

    let count = 0;
    state.collections.forEach(collection => {count += collection.items.length})

    let url = buildSearchPageQuery(state);

    submitButtons.forEach(function (btn) {
        btn.href = count > 0 ? url : '/suche';
    })
}

export function initSiteSearch () {
    document.addEventListener('keydown', function (e) {
        if (e.key === 'Enter') {
            let activeItem = document.querySelector('.aa-Item[aria-selected="true"] a');
            if(activeItem) {
                window.location.href = activeItem.href;
            }
        }
    });

    const searchPageEl = document.getElementById('autocomplete-search');

    const desktopSettings = {
        container: '#autocomplete-desktop',
        placeholder: 'Was suchen Sie?',
        openOnFocus: false,
        autoFocus: true,
        detachedMediaQuery: 'none',
        panelContainer: '#ac-results-desktop',
        panelPlacement: 'input-wrapper-width',
        debug: false,

        onStateChange({ state }) {
            updateOnStateChange(state);
        },

        onSubmit({state, event}) {

            let url = buildSearchPageQuery(state);
            // sendEvent('searchfield', 'submit', state.query);
            window.location.href = url;
        },

        getSources({ query }) {
            return [
                {
                    sourceId: 'pages',
                    getItems() {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: currentPage,
                                    query,
                                    params: {
                                        hitsPerPage: 10,
                                        attributesToSnippet: ['name:10', 'content:15'],
                                        snippetEllipsisText: '…',
                                    },
                                },
                            ],
                        });
                    },

                    templates: {
                        item({ item, components, html }) {
                            return html`
                                <a class="aa-ItemContentBody" href="${item.path}">
                                    <div class="aa-ItemContentTitle">
                                        ${components.Highlight({hit: item, attribute: 'title', tagName: 'em'})}
                                    </div>
                                    <div class="aa-ItemContentDescription">
                                        ${components.Snippet({hit: item, attribute: 'content', tagName: 'em'})}
                                    </div>
                                </a>
                            `;
                        },

                        // noResults() {
                        //     return 'No results.';
                        // },
                    },

                    getItemUrl({ item }) {
                        return item.url;
                    },

                },
            ];
        }
    };
    const mobileSettings = {
        container: '#autocomplete-mobile',
        placeholder: 'Was suchen Sie?',
        openOnFocus: false,
        autoFocus: true,
        detachedMediaQuery: 'none',
        panelContainer: '#ac-results-mobile',
        panelPlacement: 'input-wrapper-width',
        debug: false,

        onStateChange({ state }) {
            updateOnStateChange(state);
        },

        onSubmit({state, event}) {

            let url = buildSearchPageQuery(state);
            // sendEvent('searchfield', 'submit', state.query);
            window.location.href = url;
        },

        getSources({ query }) {
            return [
                {
                    sourceId: 'pages',
                    getItems() {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: currentPage,
                                    query,
                                    params: {
                                        hitsPerPage: 10,
                                        attributesToSnippet: ['name:10', 'content:15'],
                                        snippetEllipsisText: '…',
                                    },
                                },
                            ],
                        });
                    },

                    templates: {
                        item({ item, components, html }) {
                            return html`
                                <a class="aa-ItemContentBody" href="${item.path}">
                                    <div class="aa-ItemContentTitle">
                                        ${components.Highlight({hit: item, attribute: 'title', tagName: 'em'})}
                                    </div>
                                    <div class="aa-ItemContentDescription">
                                        ${components.Snippet({hit: item, attribute: 'content', tagName: 'em'})}
                                    </div>
                                </a>
                            `;
                        },

                        // noResults() {
                        //     return 'No results.';
                        // },
                    },

                    getItemUrl({ item }) {
                        return item.url;
                    },

                },
            ];
        }
    };

    let desktopS = null;
    let mobileS = null;

    document.body.addEventListener('click', e => {
        let target = e.target;
        if(target.closest('[data-trigger="search"]')) {
            if (window.matchMedia("(min-width: 1201px)").matches) {
                if(desktopS === null) desktopS = autocomplete(desktopSettings);
            } else {
                if(mobileS === null) mobileS = autocomplete(mobileSettings);
            }
        }
    })

    // function searchInitLogic() {
    //     if(!mobileS) mobileS = autocomplete(mobileSettings);
    //     if(!desktopS) desktopS = autocomplete(desktopSettings);
    // }
    //
    // let searchInitDebounced = debounce(searchInitLogic);
    // window.addEventListener('resize', searchInitDebounced);

    if(searchPageEl) {
        const searchPageSettings = {
            container: searchPageEl,
            placeholder: 'Was suchen Sie?',
            openOnFocus: false,
            autoFocus: true,
            detachedMediaQuery: 'none',
            panelContainer: '#ac-results-search',
            panelPlacement: 'input-wrapper-width',
            debug: false,

            onStateChange({ state }) {
                updateOnStateChange(state);
            },

            onSubmit({state, event}) {

                let url = buildSearchPageQuery(state);
                // sendEvent('searchfield', 'submit', state.query);
                window.location.href = url;
            },

            getSources({ query }) {
                return [
                    {
                        sourceId: 'pages',
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: currentPage,
                                        query,
                                        params: {
                                            hitsPerPage: 10,
                                            attributesToSnippet: ['name:10', 'content:15'],
                                            snippetEllipsisText: '…',
                                        },
                                    },
                                ],
                            });
                        },

                        templates: {
                            item({ item, components, html }) {
                                return html`
                                <a class="aa-ItemContentBody" href="${item.path}">
                                    <div class="aa-ItemContentTitle">
                                        ${components.Highlight({hit: item, attribute: 'title', tagName: 'em'})}
                                    </div>
                                    <div class="aa-ItemContentDescription">
                                        ${components.Snippet({hit: item, attribute: 'content', tagName: 'em'})}
                                    </div>
                                </a>
                            `;
                            },

                            // noResults() {
                            //     return 'No results.';
                            // },
                        },

                        getItemUrl({ item }) {
                            return item.url;
                        },

                    },
                ];
            }
        }
        let searchS = autocomplete(searchPageSettings);
    }

}
