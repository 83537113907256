import {slideDown, slideUp, fadeIn, debounce, fadeOut, hasClass, addClass, removeClass, addStyle} from "./helpers";

var searchTriggers = document.querySelectorAll('[data-trigger="search"]');
var headerMenu = document.querySelector('[data-el="header-menu"]');
var desktopSearch = document.querySelector('#header-search');
var mobileSearch = document.querySelector('#mobile-search');
var searchSearch = document.querySelector('#search-search');
// Removed as not in use currently
// var searchInputDesktop = desktopSearch.querySelector('.header_search-input');
// var searchInputMobile = mobileSearch.querySelector('.header_search-input');
var searchOverlay = document.querySelector('.search-overlay');

export function initSearch() {

    // searchInputDesktop.addEventListener('focus', function() {
    //     addClass(this.parentNode, 'is-focused');
    // })

    // Completely close Mobile Search
    function closeSearch() {
        headerMenu.style.opacity = '1';
        searchTriggers.forEach(function(trigger) {
            removeClass(trigger, 'is-clicked');
        })
        removeClass(searchOverlay, 'is-visible');
        fadeOut(searchOverlay);
        addStyle(mobileSearch, 'display', 'none');
        addStyle(desktopSearch, 'display', 'none');
        // removeClass(searchInputDesktop.parentNode, 'is-focused');
    }

    searchTriggers.forEach(function(trigger) {
        trigger.addEventListener('click', function() {
            if(hasClass(this, 'is-clicked')) {
                closeSearch();
            } else {
                headerMenu.style.opacity = '0';
                addClass(this, 'is-clicked');
                addClass(searchOverlay, 'is-visible');
                fadeIn(searchOverlay);

                if (window.matchMedia("(max-width: 1200px)").matches) {
                    addStyle(mobileSearch, 'display', 'block');
                    // searchInputMobile.focus();
                    let input = document.querySelector('#autocomplete-mobile').querySelector('input');
                    if(input) input.focus();
                } else {
                    addStyle(desktopSearch, 'display', 'block');
                    // searchInputDesktop.focus();
                    let input = document.querySelector('#autocomplete-desktop').querySelector('input');
                    if(input) input.focus();
                }
            }
        })
    })

    // Function to close menu
    var closeSearchDebounced = debounce(closeSearch);

    // Close if scrolled Horizontaly
    var width = window.innerWidth;
    window.addEventListener('resize', function() {
        if(this.innerWidth !== width){
            width = this.innerWidth;
            closeSearchDebounced();
        }
    }); // Debounce closing menu on resize

    // Close when clicked on Overlay
    searchOverlay.addEventListener('click', closeSearch);

    // Close on Escape
    document.addEventListener('keydown', function(event){
        if(event.key === "Escape"){
            closeSearch();
        }
    });

    var navTriggers = document.querySelectorAll('[data-nav-trigger]');
    navTriggers.forEach(function(trigger) {
        trigger.addEventListener('click', function() {
            searchTriggers.forEach(function(trigger) {
                removeClass(trigger, 'is-clicked');
            })
            // closeSearch();
            // fadeOut(searchOverlay);
            closeSearch();

            slideUp(mobileSearch, 300);
        });
    })

}
