import $ from 'jquery';
import {addStyle} from "./helpers";

var htmlDom = document.querySelector('html');
var menuTrigger = $('[data-trigger="menu"]');
var galleryEl = $('[data-element="gallery"]');

function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth + 'px';
}

export function setMargins() {
    var GSW = getScrollbarWidth();

    addStyle(htmlDom, 'overflow-y', 'hidden');
    addStyle(htmlDom, 'margin-left', 'calc(100vw - 100%)');
    addStyle(htmlDom, 'margin-right', GSW);

    addStyle(document.querySelector('header.header'), 'margin-right', GSW);

    if(window.matchMedia('(min-width: 768px)').matches) {
        addStyle(document.querySelector('.vbcn'), 'margin-right', GSW);
    }
}

export function unsetMargins() {
    setTimeout(function(){

        addStyle(htmlDom, 'overflow-y', 'scroll');
        addStyle(htmlDom, 'margin-left', 'auto');
        addStyle(htmlDom, 'margin-right', 'auto');

        addStyle(document.querySelector('header.header'), 'margin-right', '0');

        addStyle(document.querySelector('.vbcn'), 'margin-right', '0');

    }, 300);
}

export function checkMargins() {
    if(menuTrigger.hasClass('is-clicked')) {
        window.matchMedia('(max-width: 992px)').matches ? setMargins() : unsetMargins();
    }
}

export function characterCounter() {

    var textareaFields = document.querySelectorAll('textarea');

    textareaFields.forEach(function(field) {
        let max = field.maxLength;
        if(max > 0) {

            field.addEventListener('change', function() {
                let chars = field.value.length;
                field.nextElementSibling.innerHTML = chars + ' / ' + max;

            })

            field.addEventListener('keyup', function() {
                let chars = field.value.length;
                field.nextElementSibling.innerHTML = chars + ' / ' + max;

            })

        }
    })

}

const scrollToButtons = document.querySelectorAll('[data-scroll-to="next"]');
const sliderHero = document.querySelector('[data-slider="hero"]');

export function scrollToNext() {

    scrollToButtons.forEach(function(button) {
        button.addEventListener('click', function(e) {
            e.preventDefault();

            if(sliderHero) {
                sliderHero.nextElementSibling.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        })
    })

}

export function bindScrollToErrors() {
    let formHasErrors = document.querySelector('#form-has-errors');
    let formSuccess = document.querySelector('#form-success');

    if (formHasErrors) formHasErrors.scrollIntoView();
    if (formSuccess) formSuccess.scrollIntoView();

}
