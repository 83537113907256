import merge from 'deepmerge';
import {
    cnEls,
    hideBackdrop,
    hideCookieNotice,
    highlightMandatory,
    initCookieNotice,
    showBackdrop,
    showCookieNotice,
    showCustomizeMode,
    toggleCustomizeMode,
    unhighlightMandatory,
    setMandatoryOptionsToChecked
} from "./cookienotice";
import {
    collectVBCNCookieData,
    enableSpecificType,
    getVBCNCookieData,
    updateVBCNCookieData,
    renewVBCNCookie,
    setVBCNCookie,
    unsetVBCNCookie,
    checkVBCNCookieState
} from "./vbcn";
import {cookieNoticeSettings, cookieNoticeTexts, cookieSettings} from "./defaults";
import {findAncestorByClass, lang, hasClass} from "./helpers";
import {collectScripts, executeScripts} from "./scripthandler";
import polyfill from './polyfills';
// require('smoothscroll-polyfill').polyfill(); // can be installed

export const cookieState = {
    cookieDomain: null,
    cookiesAllowed: false,
    allowedData: null,
    currentPath: null,
    currentHash: null,
    settings: {},
};

export default function init (options) {

    if(!options.sets || Object.keys(options.sets).length === 0) {
        console.error('VBCN: no `sets` defined. Aborting');
        return false;
    }

    const defaults = {
        cookie: cookieSettings,
        notice: cookieNoticeSettings,
        texts: cookieNoticeTexts,
        sets: options.sets
    }
    cookieState.settings = merge(defaults, options);

    if(options.sets) {
        cookieState.settings.sets = options.sets;
    }

    if(cookieState.settings.cookie.secure && window.location.protocol !== "https:" ) {
        console.warn('VBCN: cookie is set to be `secure` only. Your connection is not')
    }

    // normalize settings
    let newSets = {};
    let newTexts = {};

    Object.keys(cookieState.settings.sets).forEach(lang => {
        let lowerlang = lang.toLowerCase();
        newSets[lowerlang] = JSON.parse(JSON.stringify(cookieState.settings.sets[lang]))
        delete cookieState.settings.sets[lang];
    })

    Object.keys(cookieState.settings.texts).forEach(lang => {
        let lowerlang = lang.toLowerCase();
        newTexts[lowerlang] = JSON.parse(JSON.stringify(cookieState.settings.texts[lang]))
        delete cookieState.settings.texts[lang];
    })

    cookieState.settings.sets = newSets;
    cookieState.settings.texts = newTexts;

    // validate settings
    if(!cookieState.settings.texts[lang]) {
        console.error('VBCN: language tag mismatch for `texts`. Aborting \n Document language: ' + lang + '. \n Available languages: ' + Object.keys(cookieState.settings.texts).join(', '))
        return false;
    }
    if(!cookieState.settings.sets[lang]) {
        console.error('VBCN: language tag mismatch for `sets`. Aborting \n Document language: ' + lang + '. \n Available languages: ' + Object.keys(cookieState.settings.sets).join(', '))
        return false;
    }

    polyfill();

    cookieState.cookieDomain = document.location.host;
    cookieState.currentPath = document.location.pathname;
    cookieState.currentHash = document.location.hash;

    if (getVBCNCookieData()) {
        cookieState.allowedData = getVBCNCookieData();
        cookieState.cookiesAllowed = cookieState.allowedData.cookiesAllowed;

        renewVBCNCookie();
    }

    collectScripts();
    initCookieNotice();
    bindCookieNoticeButtons();

    bindPlaceholders();
    bindExternalInteraction();

    if (cookieState.cookiesAllowed && cookieState.allowedData) {
        window.cookieState = cookieState;
        enableAllowedScripts(cookieState.allowedData.options);
    } else {
        window.cookieState = null;
    }

    return {
        state: cookieState,
        enableType: enableType,
        updateCookieData: updateCookieData,
        getCookieData: getVBCNCookieData,
        checkState: checkState,
    }
}

export function updateCookieData(key, data) {
    if(window.cookieState && window.cookieState.cookiesAllowed ) {
        updateVBCNCookieData(key, data);
        return true
    }

    return false;
}

export function checkState(type) {
    return checkVBCNCookieState(type)
}

export function enableType(scriptType) {

    let found = cookieState.settings.sets[lang].find(cookieSet => {
        return cookieSet.id === scriptType
    });

    if (!found) {
        console.warn('type does not exist');
        return false;
    }
    enableSpecificType(scriptType);
    removePlaceHoldersForType(scriptType);
    executeScripts(scriptType);
    hideBackdrop();
    hideCookieNotice();
    return true;
}

function enableAllowedScripts(options) {
    for (let option in options) {
        if (options.hasOwnProperty(option)) {

            if(options[option] === true) {
                removePlaceHoldersForType(option);
                executeScripts(option);
            }
        }
    }
}

function removePlaceHoldersForType(type) {
    let placeholders  = document.querySelectorAll('.js-vbcn-execute');
    let typePlaceholders = Array.from(placeholders).filter(placeholder => placeholder.dataset.type === type);

    typePlaceholders.forEach(placeholder => {
        let parent = findAncestorByClass(placeholder, 'js-vbcn-placeholder');
        parent.parentNode.removeChild(parent);
    })
}

function bindCookieNoticeButtons() {

    if(!cookieState.settings.notice.hasCustomCustomizeButton) {
        cnEls.buttons.customize.addEventListener('click', function () {
            toggleCustomizeMode();
        });
    }

    cnEls.buttons.cancel_customize.addEventListener('click', function() {
        toggleCustomizeMode();
    });

    cnEls.buttons.accept_preset.addEventListener('click', function() {
        let cookieValue = {
            cookiesAllowed: true,
            options: {}
        };

        cookieState.settings.sets[lang].forEach(cookieSet => {
            cookieValue.options[cookieSet.id] = true;
        });

        setVBCNCookie(cookieValue);
        hideBackdrop();
        hideCookieNotice();
        unhighlightMandatory();
        enableAllowedScripts(cookieValue.options);
    });

    cnEls.buttons.save.addEventListener('click', function() {
        let cookieValue = collectVBCNCookieData();
        if(!cookieValue.cookiesAllowed) {
            highlightMandatory();
            return;
        }

        setVBCNCookie(cookieValue);
        hideBackdrop();
        hideCookieNotice();
        unhighlightMandatory();
        enableAllowedScripts(cookieValue.options)
    });

    if(cookieNoticeSettings.hasDeclineButton && !cookieState.settings.notice.hasCustomDeclineButton) {
        cnEls.buttons.decline.addEventListener('click', function () {
            handleDecline();
        });
    }

    if(cookieNoticeSettings.hasDeclineButton  && !cookieState.settings.notice.hasCustomDeclineButton) {
        cnEls.cnBackdrop.addEventListener('click', function() {
            handleDecline();
            // hideBackdrop();
            // hideCookieNotice();
        });
    }
}

function bindPlaceholders() {
    let placeholders  = document.querySelectorAll('.js-vbcn-execute');

    Array.from(placeholders).forEach(placeholder => {
        placeholder.addEventListener('click', function(e) {
            let scriptType = e.currentTarget.dataset.type.toLowerCase();
            enableType(scriptType)
        })
    });
}

function bindExternalInteraction() {
    document.addEventListener('click', (event) => {
        if(hasClass(event.target, 'js-vbcn-customize')) {
            event.preventDefault();
            showBackdrop();
            showCookieNotice();
            showCustomizeMode();
        }
        if(hasClass(event.target, 'js-vbcn-decline')) {
            event.preventDefault();
            handleDecline();
        }
    })

}

function handleDecline() {
    if (!cookieNoticeSettings.declineMode || cookieNoticeSettings.declineMode === "full") {
        unsetVBCNCookie();
        unhighlightMandatory();
    } else {
        if (cookieNoticeSettings.declineMode === "session") {
            cookieState.settings.cookie.lifeTimeUnit = 'session'
        } else if (!isNaN(parseInt(cookieNoticeSettings.declineMode))) {
            cookieState.settings.cookie.lifeTimeUnit = 'day'
            cookieState.settings.cookie.cookieLifeTime = parseInt(cookieNoticeSettings.declineMode);
        } else {
            console.warn(`passed an invalid value to "declineMode": "${cookieNoticeSettings.declineMode}"`)
            return false;
        }
        unsetVBCNCookie();
        setMandatoryOptionsToChecked();
        unhighlightMandatory()
        let cookieValue = collectVBCNCookieData();

        setVBCNCookie(cookieValue);

    }

    hideBackdrop();
    hideCookieNotice();
}
