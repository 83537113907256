function getLocalStorage (sKey) {
    if (!sKey) {
        return null;
    }
    return localStorage.getItem(sKey);
}

export function setLocalStorage(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
        return false;
    }

    localStorage.setItem(sKey, sValue);
    return true;

}

export function hasLocalStorage(sKey) {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
        return false;
    }
    return !! getLocalStorage(sKey);
}

export function removeLocalStorage(sKey, sPath, sDomain) {
    if (!hasLocalStorage(sKey)) {
        return false;
    }
    localStorage.removeItem(sKey);
    return true;
}

export default {
    get: getLocalStorage,
    set: setLocalStorage,
    has: hasLocalStorage,
    remove: removeLocalStorage,
}
