import {checkState} from "../vbcn";
import {hasClass} from "./helpers";

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}


var contrastButtons = document.querySelectorAll('[data-nav-trigger="contrast"]');

export function initContrastStyle() {

    contrastButtons.forEach(function(button) {
        button.addEventListener('click', function() {
            if(hasClass(document.body, 'alt')) {
                document.body.classList.remove('alt');
                contrastButtons.forEach(btn => btn.classList.remove('is-alt'));
                if(checkState('style')) {
                    document.cookie = `contrast=active; path=/; max-age=0;`;
                }
            } else {
                document.body.classList.add('alt');
                contrastButtons.forEach(btn => btn.classList.add('is-alt'));
                if(checkState('style')) {
                    setCookie('contrast', 'active', 30);
                }
            }
        })
    })

    if(checkState('style')) {
        if(getCookie('contrast')) {
            document.body.classList.add('alt');
            contrastButtons.forEach(btn => btn.classList.add('is-alt'));
        }
    }

}
