"use strict";

import 'es6-promise/auto';
require('array.prototype.find').shim();
require('smoothscroll-polyfill').polyfill();

import {initHeader} from "./components/header";
import {initHeroSlider, initContentSlider, initCertificateSlider, initQuoteSlider} from "./components/sliders";
import {initAccordions} from "./components/accordions";
import {galleryFrame, galleryLightbox} from "./components/gallery";
import {initModal} from "./components/modal";
import {characterCounter, scrollToNext, bindScrollToErrors} from "./components/snippets";
import {initCookieNotice} from './components/cookie-notice';
import {initSearch} from "./components/search";
import {initContrastStyle} from "./components/contrast";
import bindArrowToTop from './components/arrow-to-top';
import {initSiteSearch} from "./components/site-search";
import {initAppLogic} from "./components/app";
import {initBrun} from "../sass/components/forms";
// import {am_autocomplete, am_autocomplete_state} from "./components/site-search";

// Document is ready!
document.addEventListener('DOMContentLoaded', function() {

    initHeader();
    initHeroSlider();
    initContentSlider();
    initCertificateSlider();
    initQuoteSlider();
    initAccordions();
    galleryFrame();
    galleryLightbox();
    initModal();
    characterCounter();
    scrollToNext();
    initSearch();
    initCookieNotice();
    initContrastStyle();
    bindArrowToTop();
    bindScrollToErrors();
    initSiteSearch();
    initAppLogic();
    initBrun();

});
