import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import {addClass, removeClass, hasClass} from "./helpers";

const heroSliders = document.querySelectorAll('[data-slider="hero"]')
const contentSliders = document.querySelectorAll('[data-slider="content"]')
const certificateSliders = document.querySelectorAll('[data-slider="certificates"]')
const quoteSliders = document.querySelectorAll('[data-slider="quote"]')

export function initHeroSlider() {

    heroSliders.forEach(function(slider) {
        let sliderEl = slider.querySelector('.slider_hero-el');
        if(sliderEl.querySelector('.swiper-wrapper').childElementCount > 1) {

            let autoPlayOption = hasClass(sliderEl, 'autoplay') ? {delay: 5000, disableOnInteraction: false} : false;

            let swiper = new Swiper(sliderEl, {
                modules: [Navigation, Autoplay],
                spaceBetween: 0,
                slidesPerView: 1,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: autoPlayOption
            });
        }
    })

}

export function initContentSlider() {

    contentSliders.forEach(function(slider) {
        let sliderEl = slider.querySelector('.slider_content-main');

        if(sliderEl.querySelector('.swiper-wrapper').childElementCount > 1) {

            let autoPlayOption = hasClass(sliderEl, 'autoplay') ? {delay: 5000, disableOnInteraction: false} : false;

            let swiper = new Swiper(sliderEl, {
                modules: [Navigation, Pagination, Autoplay],
                spaceBetween: 0,
                slidesPerView: 1,
                loop: true,
                navigation: {
                    prevEl: sliderEl.parentNode.querySelector('.swiper-button-prev'),
                    nextEl: sliderEl.parentNode.querySelector('.swiper-button-next'),
                },
                pagination: {
                    el: sliderEl.parentNode.querySelector('.swiper-pagination'),
                    type: 'bullets',
                    clickable: true
                },
                autoplay: autoPlayOption,
                on: {
                    init: function (swiper) {
                        if(hasClass(sliderEl, 'not-shared-desc')) {
                            let color = swiper.el.querySelector('.slider_content-slide').dataset.bg;
                            let content = swiper.el.parentNode.querySelector('.slider_content-aside');
                            if(content) {
                                addClass(content, color);
                            }
                        }
                    },
                    slideChange: function (swiper) {
                        if(hasClass(sliderEl, 'not-shared-desc')) {
                            let index = swiper.activeIndex;
                            let slide = this.el.querySelectorAll('.slider_content-slide')[index];
                            let color = slide.dataset.bg;
                            let content = swiper.el.parentNode.querySelector('.slider_content-aside');

                            if(content) {
                                content.classList = '';
                                addClass(content, 'slider_content-aside');
                                addClass(content, color);
                            }
                        }
                    },
                },
            });

        }
    })

}

let slidesFive = {
    576: {
        slidesPerView: 2,
    },
    768: {
        slidesPerView: 3,
    },
    992: {
        slidesPerView: 4,
        spaceBetween: 24
    },
    1200: {
        slidesPerView: 5,
        spaceBetween: 24
    },
}

let slidesTwo = {
    576: {
        slidesPerView: 2,
    }
}

export function initCertificateSlider() {

    certificateSliders.forEach(function(slider) {

        let wrapper = slider.querySelector('.swiper-wrapper');
        if(wrapper.childElementCount > 1) {

            let breaktpointsLogic;
            let noOfSlides = slider.dataset.slides;
            if(+noOfSlides === 5) {
                breaktpointsLogic = slidesFive;
            } else if(+noOfSlides === 2) {
                breaktpointsLogic = slidesTwo;
            } else {
                breaktpointsLogic = 1;
                // breaktpointsLogic = {};
            }

            let autoPlayOption = hasClass(slider, 'autoplay') ? {delay: 5000, disableOnInteraction: false} : false;

            let swiper = new Swiper(slider, {
                modules: [Navigation, Autoplay],
                spaceBetween: 32,
                slidesPerView: 1,
                loop: false,
                autoHeight: false,
                navigation: {
                    prevEl: slider.parentNode.querySelector('.swiper-button-prev'),
                    nextEl: slider.parentNode.querySelector('.swiper-button-next'),
                },
                autoplay: autoPlayOption,
                breakpoints: breaktpointsLogic,
                on: {
                    init: function () {
                        this.virtualSize > this.size ? removeClass(this.el, 'is-centered') : addClass(this.el, 'is-centered');
                    },
                    slideChange: function () {
                        console.log(this.el);
                        this.virtualSize > this.size ? removeClass(this.el, 'is-centered') : addClass(this.el, 'is-centered');
                    },
                    resize: function () {
                        this.virtualSize > this.size ? removeClass(this.el, 'is-centered') : addClass(this.el, 'is-centered');
                    },
                }
            });

        }

    })

}

export function initQuoteSlider() {

    quoteSliders.forEach(function(slider) {
        let sliderEl = slider.querySelector('.swiper-wrapper');
        console.log(sliderEl);
        if(sliderEl.childElementCount > 1) {

            let quoteSliderInstance = new Swiper(slider, {
                modules: [Navigation, Autoplay],
                spaceBetween: 0,
                slidesPerView: 1,
                loop: false,
                navigation: {
                    nextEl: slider.nextElementSibling.querySelector('.swiper-button-next'),
                    prevEl: slider.nextElementSibling.querySelector('.swiper-button-prev'),
                },
                autoplay: false
            });

            quoteSliderInstance.on('activeIndexChange', function (swiper) {
                let counter = swiper.el.nextElementSibling.querySelector('.quoteslider-counter span');
                counter.innerText = +swiper.activeIndex + 1;
            })
        }
    })

}
