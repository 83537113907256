export function initBrun() {
    let tshirt = document.querySelector('[id="t_shirt_none"]');
    let sectionbrun1 = document.querySelector("#section-brun-1");
    let sectionbrun2 = document.querySelector("#section-brun-2");
    let sectionbrun3 = document.querySelector("#section-brun-3");

    if (tshirt) {
        tshirt.addEventListener("change", (event) => {
            if (event.target.value == 'Ich habe noch kein T-Shirt') {
                if (sectionbrun1) sectionbrun1.style.display="block";
                if (sectionbrun2) sectionbrun2.style.display="block";
                if (sectionbrun3) sectionbrun3.style.display="block";
            }
        });
    }
}
