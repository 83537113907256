"use strict";
import {addClass, removeClass, debounce} from "./helpers";

require('smoothscroll-polyfill').polyfill();

const defaults = {
    selectors: {
        arrowToTop: '[data-to="top"]',
    },
    settings: {
        scrollDuration: 400,
    }
};

export default function bindAllScrollTop() {
    let links = document.querySelectorAll(defaults.selectors.arrowToTop);

    Array.from(links).forEach(link => {

        if (!link) return false;

        document.addEventListener('scroll', function(event) {
            window.scrollY > 500 ? addClass(link, 'is-visible') : removeClass(link, 'is-visible');
        })

        link.addEventListener('click', function(event) {
            event.preventDefault();
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        })
    })
}
