import init from "../vbcn";

const vbcnOptions = {
    notice: {
        ignoredPages: [
            '/no-data'
        ],
        ignoredHashes: [
            '#hide-my-stuff',
        ],
        backdropColor: 'red',
        centerMode: false,
        useBackdrop: false,
        analytics: {
            addInfo: true,
        }
    },
    texts: {
        "de": {
            explanationText: "Nach Ihrer Zustimmung verwenden wir Cookies um die Anzeige zu optimieren. \n\n " +
                "Zur Analyse der Zugriffe auf unsere Website verwenden wir Google Analytics. \n\n" +
                "Außerdem binden wir Skripte von YouTube und Google Maps ein. \n\n" +
                "Dabei können personenbezogene Daten an diese Anbieter übermittelt werden. Bitte klicken Sie <a href='/datenschutz'>hier</a>, um Informationen zum Datenschutz zu erhalten.",
            configuration_explanation_headline: "Was ist ein Cookie?",
            configuration_explanation_text: "Unter <strong>Cookie</strong> versteht man eine kleine <strong>Datei</strong>, beim Besuch von Internetseiten auf Deinem Computer oder Smartphone gespeichert wird. Besuchst Du die Website zu einem späteren Zeitpunkt wieder, wird der gespeicherte Cookie an den Webserver übertragen.",
        },
        "en": {
            explanationText: "On your consent cookies are set to optimize your experience on our website. \n\n" +
                "We use Google Analytics to analyze the usage  of our website. \n\n" +
                "We embed scripts from these third party service providers: YouTube \n\n" +
                "On activation of one or more of these services personal data might be forwarded to these partners. Further information you find <a href='/datenschutz'>here</a> in our privacy statement.",
            configuration_explanation_headline: "What is a cookie?",
            configuration_explanation_text: "A <strong>cookie</strong> is a small <strong>data file</strong> that is stored when visiting a website on your computer or smartphone. As a result, the stored cookie will be transmitted to the web server each time you visit the website again.",
        },
        "it": {
            explanationText: "Con il vostro consenso, utilizziamo cookie per ottimizzare la visualizzazione. \n\n" +
                "Per l'analisi degli accessi al nostro sito web utilizziamo Google Analytics. \n\n" +
                "Inoltre facciamo uso anche di script di YouTube. \n\n " +
                "È possibile quindi che dei dati personali vengano trasmessi a tali offerenti. <a href='/datenschutz'>Cliccate quix</a> per ricevere informazioni sulla protezione dei dati.",
            configuration_explanation_headline: "What is a cookie?",
            configuration_explanation_text: "A <strong>cookie</strong> is a small <strong>data file</strong> that is stored when visiting a website on your computer or smartphone. As a result, the stored cookie will be transmitted to the web server each time you visit the website again.",
        }
    },
    sets: {
        "de": [
            {
                id: 'mandatory',
                name: 'Notwendige Cookies',
                description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Sie speichern keine personenbezogenen Daten.',
                mandatory: true,
                mandatory_text: "Diese Cookies sind notwendig, um Ihre Einstellungen zu speichern."
            }, {
                id: 'style',
                name: 'Styling Cookies',
                description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Sie speichern keine personenbezogenen Daten.'
            }, {
                id: 'google_maps',
                name: 'Google Maps',
                description: 'Diese Skripte und Cookies sind nötig, um die Karten des Dienstes darzustellen. Durch das Aktivieren können personenbezogene Daten an Google übertragen werden.'
            }, {
                id: 'google_analytics',
                name: 'Google Analytics',
                description: 'Diese Skripte und Cookies werden eingebunden, um mehr über die Besucher unserer Website zu erfahren - wie zum Beispiel Herkunft, Bildschirmauflösung oder verwendeter Browser.'
            }, {
                id: 'youtube_video',
                name: 'YouTube Video',
                description: 'Diese Skripte und Cookies sind nötig, um YouTube Videos auf dieser Seite abzuspielen. Durch das Aktivieren können personenbezogene Daten an YouTube übertragen werden.'
            }, {
                id: 'vimeo_video',
                name: 'Vimeo Videos',
                description: 'Diese Skripte und Cookies sind nötig, um Vimeo Videos auf dieser Seite abzuspielen. Durch das Aktivieren können personenbezogene Daten an Vimeo übertragen werden.'
            }
        ],
        "en": [
            {
                id: 'mandatory',
                name: 'Essential cookies',
                description: 'Cookies that ensure the functionality and the design of our webpages. No personal data are collected. These cookies are required to save your session settings.',
                mandatory: true,
                mandatory_text: "These cookies are mandatory."
            }, {
                id: 'style',
                name: 'Styling Cookies',
                description: 'Cookies that ensure the design of our webpages. No personal data are collected.'
            }, {
                id: 'google_maps',
                name: 'Google Maps',
                description: 'Scripts and cookies that are required to display the maps of this service. On activation personal data might be forwarded to Google.'
            }, {
                id: 'google_analytics',
                name: 'Google Analytics',
                description: 'Scripts and cookies that gather information about visitors of the site, for example country of origin, device settings and browsers.'
            }, {
                id: 'youtube_video',
                name: 'YouTube Video',
                description: 'Scripts and cookies that are required to play videos of this service on this website. On activation personal data might be forwarded to YouTube (Google).'
            }, {
                id: 'vimeo_video',
                name: 'Vimeo Videos',
                description: 'Scripts and cookies that are required to play videos of this service on this website. On activation personal data might be forwarded to Vimeo.'
            }
        ]
    }
}

export function initCookieNotice() {
    window.myVBCN = init(vbcnOptions);
}
