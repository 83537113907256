import $ from 'jquery';
import imagesLoaded from 'imagesloaded';
import magnificPopup from "magnific-popup";
import {fadeIn, fadeOut} from "./helpers";
import {setMargins, unsetMargins} from "./snippets";

var galOpen = document.querySelector('[data-gallery="open"]');
var galFrame = document.querySelector('[data-gallery="frame"]');
var galClose = document.querySelectorAll('[data-gallery="close"]');

export function galleryFrame() {

    if(galOpen && galFrame) {

        galOpen.addEventListener('click', function() {
            let images = galFrame.querySelectorAll('img');
            images.forEach(function (img) {
                img.src = img.dataset.src;
            })
            fadeIn(galFrame);
            setMargins();
        })

        galClose.forEach(function(button) {
            button.addEventListener('click', function(e) {
                fadeOut(galFrame);
                unsetMargins();

                e.preventDefault();
            })
        })

    }

}

export function galleryLightbox() {

    // Translation
    $.extend(true, $.magnificPopup.defaults, {
        tClose: 'X (Esc)',
        tLoading: '...',
        gallery: {
            tPrev: '&#8592;',
            tNext: '&#8594;',
            tCounter: '%curr% / %total%'
        },
        image: {tError: 'x-x'},
        ajax: {tError: 'x-x'}
    });

    if ($('.gallery-list').length) {
        $('.gallery-list').each(function () {
            $(this).magnificPopup({
                delegate: '.gallery-list-link',
                type: 'image',
                gallery: {enabled: true},
                image: {
                    titleSrc: function (item) {
                        if (item.el.find('.gallery-meta').html() === undefined) {
                            return '<small></small>';
                        } else {
                            return item.el.find('.gallery-meta').html();
                        }
                    }
                }
            });
        });
    }

}
