import {fadeIn, fadeOut} from "./helpers";
import {setMargins, unsetMargins} from "./snippets";

var modalOpen = document.querySelectorAll('[data-login-open]');
var modalClose = document.querySelectorAll('[data-login="close"]');
var modalFrame = document.querySelectorAll('[data-login-modal]');
// var modalFrame = document.querySelectorAll('[data-gallery="frame"]');

export function initModal() {

    modalOpen.forEach(function(button) {
        button.addEventListener('click', function() {
            let modalAddress = this.dataset.loginOpen;
            let modalLocation = document.querySelector('[data-login-modal="' + modalAddress + '"]');

            if(modalLocation) {
                fadeIn(modalLocation);
                setMargins();
            }
        })
    })

    modalClose.forEach(function(button) {
        button.addEventListener('click', function() {

            modalFrame.forEach(function(modal) {
                fadeOut(modal);
                unsetMargins();
            })

        })
    })

}
