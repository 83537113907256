import {addClass, removeClass, hasClass, addStyle, fadeIn, fadeOut, debounce, getParentsUntil, getNextUntil} from "./helpers";
import Headroom from 'headroom.js';

// SOME IMPORTS EXPLAINED
// getParentsUntil: Needed to get specific parent elements. When opening nth menu directly from breadcrumb we need to show previous parents otherwise menu will not be visible
// getNextUntil: Needed to get siblings after clicked one in beadcrumbs so we can remove tham when clicking back

// We will declare these later
const elements = {
    headerEl:            null, // Main Header Element
    navOverlay:          null, // Overlay for navigation (shows when navigation is opened)
    navMobileTrigger:    null, // Triggers that are opening navigation from main menu
    navMenuItems:        null, // Triggers that are opening navigation from main menu
    navMenuItemActive:   null, // Active menu item by default (on load)
    navBcrumbMenu:       null, // Breadcrumb bars (2 of them: Standard and Alternative)
    navBcrumbClose:      null, // Button to close Menu completely, positioned on Breadcrumb bar
    navBcrumbItems:      null, // Triggers that are opening menu from breadcrumb
    navBcrumbItemActive: null, // Active breadcrumb item by default (on load)
    navBcrumbStd:        null, // Standard Breadcrumb bar
    navBcrumbAlt:        null, // Alternative Breadcrumb bar (used to clone Standard Breadcrumb bar)
    navContainers:       null  // Navigation Containers (used to display submenus when clicked on Menu or Breadcrumb item)
}

// What will happen to complete Header element on scroll
function initHeaderOnScroll(el) {
    if(Headroom) {
        var headroom = new Headroom(el, {
            offset: {
                up: el.offsetHeight / 3,
                down: 111,
            }
        });
        headroom.init();

        if(window.scrollY > 111) {
            headroom.unpin();
        }

        document.addEventListener('click', function (e) {
            let searchOverlay = document.querySelector('.search-overlay');
            let navOverlay = document.querySelector('.nav-overlay');

            if(searchOverlay && navOverlay) {
                hasClass(searchOverlay, 'is-visible') || hasClass(navOverlay, 'is-visible') ? headroom.freeze() : headroom.unfreeze();
            }
        })
    }


    // $(window).on('scroll', function() {
    //   window.scrollY > el.height() ? el.addClass//('is-small') : el.removeClass('is-small');
    // })
}

// Functions down below are enough self explainatory
function hideOverlay() {
    console.log('hideOverlay');
    if(hasClass(elements.navOverlay, 'is-visible')) {
        removeClass(elements.navOverlay, 'is-visible');
        fadeOut(elements.navOverlay);
    }
}

function showOverlay() {
    console.log('showOverlay');
    if(!hasClass(elements.navOverlay, 'is-visible')) {
        addClass(elements.navOverlay, 'is-visible');
        fadeIn(elements.navOverlay);
    }
}

function showNavMobileTrigger() {
    elements.navMobileTrigger.forEach(function(trigger) {
        removeClass(trigger, 'is-clicked');
        addStyle(trigger, 'display', 'block');
    })
}

function hideNavMobileTrigger() {
    elements.navMobileTrigger.forEach(function(trigger) {
        addClass(trigger, 'is-clicked');
        addStyle(trigger, 'display', 'none');
    })
}

function hideClickedMenuItems() {
    elements.navMenuItems.forEach(function(item) {
        removeClass(item.parentNode, 'is-clicked');
    })
}

function hideActiveMenuItem() {
    removeClass(elements.navMenuItemActive, 'is-active');
}

function showActiveMenuItem() {
    addClass(elements.navMenuItemActive, 'is-active');
}

function hideClickedBcrumbItems() {
    elements.navBcrumbItems.forEach(function(item) {
        removeClass(item.parentNode, 'is-clicked');
    })
}

function hideActiveBcrumbItem() {
    if(elements.navBcrumbItemActive) {
        removeClass(elements.navBcrumbItemActive, 'is-active');
    }
}

function showActiveBcrumbItem() {
    if(elements.navBcrumbItemActive) {
        addClass(elements.navBcrumbItemActive, 'is-active');
    }
}

function showBreadCrumbClose() {
    elements.navBcrumbClose.forEach(function(close) {
        addStyle(close, 'display', 'block');
    })
}

function hideBreadCrumbClose() {
    elements.navBcrumbClose.forEach(function(close) {
        addStyle(close, 'display', 'none');
    })
}

function showBcrumbStd() {
    addStyle(elements.navBcrumbStd, 'display', 'block');
}

function hideBcrumbStd() {
    addStyle(elements.navBcrumbStd, 'display', 'none');
}

function showBcrumbAlt() {
    addStyle(elements.navBcrumbAlt, 'display', 'block');
}

function hideBcrumbAlt() {
    addStyle(elements.navBcrumbAlt, 'display', 'none');
}

function removeFadedBcrumbItems() {
    let bcrumbItemsa = elements.navBcrumbStd.querySelectorAll('.header-bcrumb-item');
    bcrumbItemsa.forEach(function (item, id) {
        if(id !== bcrumbItemsa.length - 1) {
            removeClass(item, 'is-faded');
        }
    })

    let bcrumbItems = elements.navBcrumbAlt.querySelectorAll('.header-bcrumb-item');
    bcrumbItems.forEach(function (item, id) {
        if(id !== bcrumbItems.length - 1) {
            removeClass(item, 'is-faded');
        }
    })
}

// If @param style is defined, then we hide Nav Container quickly
// except for specific container where we add animation
function hideNavContainers(style) {
    elements.navContainers.forEach(function(container) {
        removeClass(container, 'is-visible');

        if(style === undefined) {

            addStyle(container, 'transition', '0.3s');
            addStyle(container, 'height', '0');

        } else {

            if(style === container.dataset.navContainer) {
                addStyle(container, 'transition', '0.3s');
            } else {
                addStyle(container, 'transition', '0s');
                addStyle(container, 'height', '0');
            }

        }
    })
}

function hideNavContainersAlt(style) {
    elements.navContainers.forEach(function(container) {
        removeClass(container, 'is-visible');
        addStyle(container, 'transition', '0.3s');
        addStyle(container, 'height', '0.3s');
    })
}

// Move Nav Track WITH transition
// 'hide' is element that should be hidden after transition
// That will happen if we're going back with Nav Tracl
function moveTrack(track, value, hide) {
    let currentValue = track.style.transform;
    let cleanValue = currentValue !== '' ? currentValue.replace(/translatex/gi,'').replace('%', '').replace('(', '').replace(')', '') : 0;
    let newValue = value === 'next' ? (+cleanValue - 100) : (+cleanValue + 100);

    if(value === 'prev' && newValue === 100) {
        newValue = 0;
    }

    addStyle(track, 'transition', '0.3s');
    addStyle(track, 'transform', 'translateX(' + newValue + '%)');

    track.addEventListener('transitionend', function() {
        addStyle(track, 'transition', '0s');
        if(hide) {
            addStyle(hide, 'display', 'none');
        }
    }, {once: true})
}

// Move Nav Track WITHOUT transition
function moveTrackQuick(track, value) {
    let newValue = (+value * 100);
    addStyle(track, 'transform', 'translateX(-' + newValue + '%)');
}

// Needed to cretae new Breadcrumb element
function createBCrumbEl(p1href, p2text, p3bcrumbopen, p4bcrumblvl, p5bcrumbid) {
    let createLi = document.createElement('li');
    let createA = document.createElement('a');
    let createSpan = document.createElement('span');
    let createSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    let createUse = document.createElementNS('http://www.w3.org/2000/svg', 'use');

    addClass(createLi, 'header-bcrumb-item');
    addClass(createA, 'header-bcrumb-link');
    createA.href = p1href;
    p5bcrumbid = p5bcrumbid === undefined ? '' : ' ' + p5bcrumbid;
    createA.setAttribute('data-nav-trigger', 'bcrumb' + ' ' + p3bcrumbopen + ' ' + p4bcrumblvl + p5bcrumbid);
    createSpan.innerHTML = p2text;
    addClass(createSVG, 'header-bcrumb-icon');
    // addClass(createSVG, 'd-block');
    createSVG.setAttribute('width', '12');
    createSVG.setAttribute('height', '12');
    createUse.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#i-chevron-down');

    createSVG.appendChild(createUse);
    createA.appendChild(createSpan);
    createA.appendChild(createSVG);
    createLi.appendChild(createA);

    return createLi;
}

// Remove all items except 'Home' in Alternative Breadcrumbs
// This is needed when clicking on menuItem, so we have only Clicked item
function clearBCrumbAlt() {
    let bcrumb = elements.navBcrumbAlt.querySelector('.header-bcrumb-list');
    while (bcrumb.childNodes.length > 2) {
        bcrumb.removeChild(bcrumb.lastChild);
    }
}

// Completely close Menu
function closeMenu() {
    document.querySelector('body').style.position = 'static';
    hideOverlay();
    hideClickedMenuItems();
    showActiveMenuItem();
    hideBreadCrumbClose();
    hideClickedBcrumbItems();
    showActiveBcrumbItem();
    hideBcrumbAlt();
    showBcrumbStd();
    removeFadedBcrumbItems();
    hideNavContainers();
    showNavMobileTrigger();
    removeClass(elements.headerEl, 'is-visible');

    // SPECIAL
    document.querySelector('.header-mnav').style.transform = 'translateX(0)';
}

function hideMenu() {
    document.querySelector('body').style.position = 'static';
    hideOverlay();
    // hideClickedMenuItems();
    // showActiveMenuItem();
    hideBreadCrumbClose();
    // hideClickedBcrumbItems();
    // showActiveBcrumbItem();
    // hideBcrumbAlt();
    // showBcrumbStd();
    // removeFadedBcrumbItems();
    // hideNavContainers();
    showNavMobileTrigger();
    removeClass(elements.headerEl, 'is-visible');

    // SPECIAL
    //document.querySelector('.header-mnav').style.transform = 'translateX(0)';
}

function openMobileMenu() {
    // document.querySelector('body').style.position = 'fixed';
    showOverlay();
    hideNavMobileTrigger();
    showBreadCrumbClose();
    addClass(elements.headerEl, 'is-visible');

    if (window.matchMedia("(max-width: 1024px)").matches) {
        let nav = document.querySelector('.nav.is-current');

        if(nav) {
            let navTrack = nav.querySelector('[data-nav-level="0"]');
            var nodes = document.querySelectorAll('.nav-current-location');

            var last = nodes[nodes.length- 1];

            nodes.forEach(function(node) {
                addStyle(node, 'display', 'block');
            })

            addClass(nav, 'is-visible');
            addStyle(navTrack, 'display', 'block');
            moveTrackQuick(navTrack, last.dataset.navLevel);

            //showOverlay();
            //hideClickedMenuItems();
            //hideActiveMenuItem();
            //hideNavContainers(targetValue);

            //addClass(target.parentNode, 'is-clicked');
            //addClass(nav, 'is-visible');
            addStyle(nav, 'height', last.scrollHeight + 'px');

            // SPECIAL
            document.querySelector('.header-mnav').style.transform = 'translateX(-100%)';



            //moveTrackQuick(navTrack, last.dataset.navLevel);
            //addStyle(nav, 'height', last.scrollHeight + 'px');
        }
    }
}

function openDesktopMenu(target) {
    let targetValue = target.dataset.navTrigger.split(" ")[1];

    if(targetValue !== undefined) {
        let nav = document.querySelector('[data-nav-container="' + targetValue + '"]');
        let navTrack = nav.querySelector('[data-nav-level="0"]');
        addStyle(navTrack, 'display', 'block');
        moveTrackQuick(navTrack, 0);

        showOverlay();
        hideClickedMenuItems();
        hideActiveMenuItem();
        hideNavContainers(targetValue);

        addClass(target.parentNode, 'is-clicked');
        addClass(nav, 'is-visible');
        addStyle(nav, 'height', nav.scrollHeight + 'px');

        // SPECIAL
        document.querySelector('.header-mnav').style.transform = 'translateX(-100%)';
    }
}

function openBcrumbDesktopMenu(target) {
    let targetValue = target.dataset.navTrigger.split(" ")[1];
    let targetLvl = +target.dataset.navTrigger.split(" ")[2];
    let targetId = target.dataset.navTrigger.split(" ")[3];

    if(targetValue !== undefined && targetLvl !== undefined) {
        let nav = document.querySelector('[data-nav-container="' + targetValue + '"]');
        let navTrack = nav.querySelector('[data-nav-level="0"]');
        let desiredUl = targetId !== undefined ? nav.querySelector('[data-nav-bcrumb-target="' + targetId + '"]') : navTrack;

        addStyle(navTrack, 'display', 'block');
        showOverlay();
        hideNavContainers(targetValue);
        addClass(nav, 'is-visible');

        nav.querySelectorAll('.nav-layer').forEach(function(layer) {
            addStyle(layer, 'display', 'none');
        })
        addStyle(desiredUl, 'display', 'block');

        let parents = getParentsUntil(desiredUl, function (parent) {
            return parent.matches('.nav-layer');
        })

        parents.forEach(function(parent) {
            addStyle(parent, 'display', 'block');
        })

        moveTrackQuick(navTrack, targetLvl);
        addStyle(nav, 'height', desiredUl.scrollHeight + 'px');
    }
}

function goNext(target) {
    let targetValue = target.dataset.navTrigger.split(" ")[1];

    if(targetValue !== undefined) {
        let nav = document.querySelector('.nav.is-visible');
        let navTrack = nav.querySelector('[data-nav-level="0"]');

        let currentUl = target.closest('[data-nav-level]');
        let desiredUl = target.parentNode.querySelector('[data-nav-level="' + (+targetValue) + '"]');

        addStyle(desiredUl, 'display', 'block');
        addStyle(nav, 'height', desiredUl.offsetHeight + 'px');
        moveTrack(navTrack, 'next');
    }
}

function goBack(target) {
    let targetValue = target.dataset.navTrigger.split(" ")[1];

    if(targetValue !== undefined) {
        let nav = document.querySelector('.nav.is-visible');
        let navTrack = nav.querySelector('[data-nav-level="0"]');

        let currentUl = target.closest('[data-nav-level]');
        // let desiredUl = nav.querySelector('[data-nav-level="' + (+targetValue) + '"]');
        // previous one doesn't work anymore cause of multiple prev items with same nav-level
        let desiredUl = currentUl.parentNode.parentNode.parentNode.parentNode;

        addStyle(nav, 'height', desiredUl.offsetHeight + 'px');
        moveTrack(navTrack, 'prev', currentUl);
    }
}

// Duplicate Items from Standard Breadcrumbs Bar
// to Alternative BreadCrumbs Bar and display them
function duplicateBreadCrumbItems() {
    let standardItems = elements.navBcrumbStd.querySelector('.header-bcrumb-list').innerHTML;
    elements.navBcrumbAlt.querySelector('.header-bcrumb-list').innerHTML = standardItems;

    hideBcrumbStd();
    showBcrumbAlt();
}

function updateBCrumbsOnOpen(target) {
    showBreadCrumbClose();
    hideClickedBcrumbItems();
    hideActiveBcrumbItem();
    clearBCrumbAlt();

    let bcrumb = elements.navBcrumbAlt.querySelector('.header-bcrumb-list');
    let newBCrumbItem = createBCrumbEl(target.href, target.innerText, target.dataset.navTrigger.split(" ")[1], '0');
    addClass(newBCrumbItem, 'is-clicked');
    bcrumb.appendChild(newBCrumbItem);

    hideBcrumbStd();
    showBcrumbAlt();
}

function updateBCrumbsOnBcrumb(target) {
    showBreadCrumbClose();
    removeFadedBcrumbItems();
    if(elements.navBcrumbAlt.querySelector('.is-clicked')) {
        removeClass(elements.navBcrumbAlt.querySelector('.is-clicked'), 'is-clicked');
    }
    addClass(target.parentNode, 'is-clicked');

    var nextStop = getNextUntil(target.parentNode);
    nextStop.forEach(function(item) {
        addClass(item, 'is-faded');
    })
}

function updateBCrumbsOnNext(target) {
    hideBcrumbStd();
    showBcrumbAlt();

    let activeOne = elements.navBcrumbAlt.querySelector('.is-clicked');
    var nextStop = getNextUntil(activeOne);

    activeOne.classList.remove('is-clicked');
    nextStop.forEach(function(item) {
        item.parentNode.removeChild(item);
    })

    // Create new breadcrumb item
    let newBCrumbItem = createBCrumbEl(target.href, target.nextElementSibling.querySelector('.nav-title').dataset.bcrumbTitle, target.dataset.navTrigger.split(" ")[2], target.dataset.navTrigger.split(" ")[1], target.dataset.navTrigger.split(" ")[3]);
    // let newBCrumbItem = createBCrumbEl(target.href, target.nextElementSibling.querySelector('.nav-title').innerText, target.dataset.navTrigger.split(" ")[2], target.dataset.navTrigger.split(" ")[1], target.dataset.navTrigger.split(" ")[3]);
    addClass(newBCrumbItem, 'is-clicked');

    // Append new breadcrumb itedm
    let bcrumb = elements.navBcrumbAlt.querySelector('.header-bcrumb-list');
    bcrumb.appendChild(newBCrumbItem);
}

function updateBCrumbsOnBack() {
    hideBcrumbStd();
    showBcrumbAlt();

    let activeOne = elements.navBcrumbAlt.querySelector('.is-clicked');
    addClass(activeOne.previousElementSibling, 'is-clicked');
    removeClass(activeOne, 'is-clicked');
    addClass(activeOne, 'is-faded');
}

function initMenu(e) {
    let target = e.target.closest('[data-nav-trigger]');
    if(target) {

        if(target.closest('[data-nav-trigger*="root"]')) {
            hideNavContainersAlt();
            document.querySelector('.header-mnav').style.transform = 'translateX(0)';
        }

        if(target.closest('[data-nav-trigger*="close"]')) {
            closeMenu();
        }

        if(target.closest('[data-nav-trigger*="hide-mobile"]')) {
            hideMenu();
        }

        if(target.closest('[data-nav-trigger*="open"]')) {
            openDesktopMenu(target);
            updateBCrumbsOnOpen(target);
        }

        if(target.closest('[data-nav-trigger*="bcrumb"]')) {
            openBcrumbDesktopMenu(target);
            updateBCrumbsOnBcrumb(target);
        }

        if(target.closest('[data-nav-trigger*="next"]')) {
            goNext(target);
            updateBCrumbsOnNext(target);
        }

        if(target.closest('[data-nav-trigger*="back"]')) {
            goBack(target);
            updateBCrumbsOnBack();
        }

        if(target.closest('[data-nav-trigger*="menu"]')) {
            // hideNavMobileTrigger();
            openMobileMenu();
        }

        if(target.closest('[data-nav-trigger="home"]')) {
            return true;
        }

        if(target.closest('[data-bcrumb="std"]')) {
            duplicateBreadCrumbItems();
        }

        e.preventDefault();
    }
}

export function initHeader() {

    // Redefine variables on Document ready
    elements.headerEl            = document.querySelector('[data-el="header"]');
    elements.navOverlay          = document.querySelector('.nav-overlay');
    elements.navMobileTrigger    = document.querySelectorAll('[data-nav-trigger*="menu"]');
    elements.navMenuItems        = document.querySelectorAll('[data-nav-trigger*="open"]');
    elements.navMenuItemActive   = document.querySelector('.header-menu-item.is-active');
    elements.navBcrumbMenu       = document.querySelectorAll('.header-bcrumb-menu');
    elements.navBcrumbClose      = document.querySelectorAll('.header-bcrumb-close');
    elements.navBcrumbItems      = document.querySelectorAll('[data-nav-trigger*="bcrumb"]');
    elements.navBcrumbItemActive = document.querySelector('[data-nav-trigger*="bcrumb"].is-active');
    elements.navBcrumbStd        = document.querySelector('[data-bcrumb="std"]');
    elements.navBcrumbAlt        = document.querySelector('[data-bcrumb="alt"]');
    elements.navContainers       = document.querySelectorAll('[data-nav-container]');

    // Close menu only on horizontal resize
    var width = window.innerWidth;
    window.addEventListener('resize', function() {
        if(elements.navMobileTrigger.length > 0 && this.innerWidth !== width){
            width = this.innerWidth;
            closeMenuDebounced();
        }
    }); // Debounce closing menu on resize

    document.addEventListener('click', initMenu); // Initialize Menu

    const searchTriggers = document.querySelectorAll('[data-trigger="search"]');
    searchTriggers.forEach(function(trigger) {
        trigger.addEventListener('click', closeMenu);
    })

    let algoliaInput = document.querySelector('#autocomplete-0-input');
    if(algoliaInput) {algoliaInput.focus();}

    initHeaderOnScroll(elements.headerEl);                    // Header on Scroll behaviour
    var closeMenuDebounced = debounce(closeMenu);
    // window.addEventListener('resize', closeMenuDebounced); // Debounce closing menu on resize

}
