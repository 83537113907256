import {hasClass, addClass, removeClass, slideDown, slideUp} from "./helpers";

var accordionElements = document.querySelectorAll('[data-el="accordions"]');

export function initAccordions() {

    accordionElements.forEach(function(accordion) {

        let accordionTriggers = accordion.querySelectorAll('[data-accordion-trigger]');
        let accordionContents = accordion.querySelectorAll('[data-accordion-content]');

        accordionTriggers.forEach(function(trigger, mainId) {
            trigger.addEventListener('click', function() {
                if(!hasClass(this.parentNode, 'is-active')) {

                    accordionTriggers.forEach(function(trigger, triggerId) {
                        triggerId !== mainId ? removeClass(trigger.parentNode, 'is-active') : addClass(trigger.parentNode, 'is-active');
                    })

                    accordionContents.forEach(function(content, contentId) {
                        contentId !== mainId ? slideUp(content, 250) : slideDown(content, 250);
                    })

                } else {

                    accordionTriggers.forEach(function(trigger) {
                        removeClass(trigger.parentNode, 'is-active');
                    })

                    accordionContents.forEach(function(content) {
                        slideUp(content, 250);
                    })

                }

            })
        })
    })

}
